// 满足repurchase按钮展示的订单状态
export const REPURCHASE_ORDER_STATUS = [0, 1, 2, 3, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]

// 福袋盲盒商品图片
export const BLIND_BOX_IMG = 'https://img.ltwebstatic.com/images3_acp/2024/04/29/fa/1714376167fb8cffc3698fecb4edc54060756b730b.png'

// 百亿补贴跳转地址
export const SubsidyJumpSite = {
  'pwar': 'https://m.shein.com/ar/terms-and-conditions-a-3266.html',
  'mjp': 'https://m.shein.com/jp/terms-and-conditions-a-3266.html',
  'pwuk': 'https://m.shein.co.uk/terms-and-conditions-a-3266.html',
  'pwfr': 'https://m.shein.com/fr/terms-and-conditions-a-3266.html',
  'pwde': 'https://m.shein.com/de/terms-and-conditions-a-3266.html',
  'pwit': 'https://m.shein.com/it/terms-and-conditions-a-3266.html',
  'pwes': 'https://m.shein.com/es/terms-and-conditions-a-3266.html',
  'pwus': 'https://m.shein.com/us/terms-and-conditions-a-3266.html',
  'default': 'https://m.shein.com/terms-and-conditions-a-3266.html'
}
