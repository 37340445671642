<template>
  <div>
    <s-dialog :visible="visible">
      <template #title>
        <div class="cond-text">
          {{ content }}
        </div>
      </template>
      <template #footer>
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click.stop="$emit('closeMallNmaeDialog')"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['closeMallNmaeDialog'],
  components: {
    SButton,
    SDialog,
  },
  props: {
    language: {
      type: Object,
      default: ()=>{}
    },
    content: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style>
.cond-text {
  word-break: break-word;
}
</style>
