<template>
  <s-drawer
    :visible="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    direction="btt"
    animate-type="half"
    type="multi"
    @update:visible="updateVisible"
    @open="onOpend"
    @close-from-icon="onClose"
  >
    <div class="add-cart-success">
      <div class="add-cart-success__header">
        <Icon 
          name="sui_icon_success_18px" 
          size="18px" 
          color="#198055"
        />
        <span class="add-cart-success__title">
          {{ isPartSuccess ? language.SHEIN_KEY_PWA_33971: successText }}
        </span>
      </div>
      <!-- 部分添加成功提示 -->
      <p 
        v-if="isPartSuccess" 
        class="add-cart-tips add-cart-tips__top"
      >
        {{ successText }}
      </p>
      <RepurchaseProductList 
        :language="language"
        :show-border="isPartSuccess"
        :repurchase-goods="successInfoList"
      />
      <!-- 添加失败提示 -->
      <div 
        v-if="isPartSuccess"
        class="add-fail-content"
      > 
        <p class="add-cart-tips">
          {{ failText }}
        </p>
        <RepurchaseProductList 
          class="add-fail-list"
          :language="language"
          :disabled="true"
          :repurchase-goods="falidInfoList"
        />
      </div>
    </div>
    <template #footer>
      <s-button
        :type="['primary', 'H80PX']"
        :block="true"
        width="100%"
        class="add-cart-success__btn"
        @click="onViewCart"
      >
        <RepurchaseNotice 
          v-if="repurchaseInfo"
          :repurchase-info="repurchaseInfo"
        />
        <Icon 
          name="sui_icon_cart_18px" 
          size="20px" 
          color="#fff" 
        />
        <span class="add-cart-success__btn-text">
          {{ language.SHEIN_KEY_PWA_33970 }}
        </span>
      </s-button>
    </template>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
import { uniq } from 'lodash'
import RepurchaseProductList from './RepurchaseProductList.vue'
import RepurchaseNotice from './RepurchaseNotice.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default defineComponent({
  name: 'AddCartSuccessDialog',
  components: {
    Icon,
    RepurchaseProductList,
    RepurchaseNotice,
    SButton,
    SDrawer,
  },
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // 成功的sku_code列表
    successInfoList() {
      return this.info.successInfoList || []
    },
    // 失败的sku_code列表
    falidInfoList() {
      return this.info.falidInfoList || [] 
    },
    // 是否部分加车成功
    isPartSuccess() {
      return Boolean(this.successInfoList.length && this.falidInfoList.length)
    },
    failText() {
      const skuCodes = uniq(this.falidInfoList.map(i => i.sku_code))
      return template(skuCodes.length, this.language.SHEIN_KEY_PWA_34395)
    },
    successText() {
      const skuCodes = uniq(this.successInfoList.map(i => i.sku_code))
      return template(skuCodes.length, this.language.SHEIN_KEY_PWA_33972)
    },
    repurchaseInfo() {
      const info = this.info.repurchaseInfo || {}
      if (info.tip && info.icon) {
        return info
      }
      return null
    }
  },
  methods: {
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    onClickRepurchasePopup(buttonType) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-170',
        extraData: {
          button_type: buttonType
        }
      })
    },
    onViewCart() {
      this.updateVisible(false)
      this.onClickRepurchasePopup('gotocart')
      const { langPath } = gbCommonInfo
      this.$routerPush(`${langPath}/cart`)
    },
    onOpend() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-169',
      })
    },
    onClose() {
      this.onClickRepurchasePopup('close')
    },
  },
})
</script>
<style lang="less" scoped>
.add-cart-success {
  padding: 0 24/75rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: @sui_color_gray_dark1;
  }
  &__title {
    margin-left: 16/75rem;
  }
  .add-cart-tips {
    color: @sui_color_brand;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    &__top {
      margin-top: 24/75rem;
    }
  }
  .add-fail-content {
    margin-top: 24/75rem;
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: initial;
    text-transform: uppercase;

    &-text {
      margin-left: 16/75rem;
    }
  }
}
</style>

